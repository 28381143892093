import React from "react"
import Navbar from "@components/pageLegal/components/navbar"
import Description from "@components/pageLegal/components/description"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"

const StructureTerminos = ({ location, data, logo }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div className="fnd">
      <BannerCookies />
      <section>
        <Navbar
          path="/ventas-telefono-ebook/"
          location={location}
          windowsWidth={windowsWidth}
          logo={logo}
        />
        <section className="container">
          <Description
            data={data.description}
            data2={data.options}
            location={location}
          />
        </section>
      </section>
    </div>
  )
}

export default StructureTerminos
